<template>
  <b-container fluid class="col-11 col-lg-10 my-lg-5 my-4 pt-lg-4">
    <b-row>
      <b-col class="pt-4" lg="6" order-lg="0" order="1">
        <div class="video-container mb-4" v-if="project.youtubeVideoId">
          <iframe
            class="youtube-video"
            :src="`https://www.youtube.com/embed/${project.youtubeVideoId}`"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
        <b-img
          fluid
          v-for="(img, index) in project.pictureUrls"
          :key="index"
          :src="img"
          class="mb-4"
        />
      </b-col>
      <b-col class="text-left" lg="6" order-lg="1" order="0">
        <h1 class="project-title">
          {{ project.title }}
          <i v-if="project.year" class="title-year">({{ project.year }})</i>
        </h1>
        <div v-html="project.description" class="project-description"></div>

        <b-row class="mt-4">
          <b-col cols="auto">
            <b-button to="/">
              Back
            </b-button>
          </b-col>
          <b-col cols="auto" class="ml-auto pl-0" v-if="project.buttons">
            <b-button
              v-for="(button, index) in project.buttons"
              :key="index"
              :href="button.url"
              target="_blank"
              class="ml-3 mt-2"
            >
              {{ button.label }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Project",
  data() {
    return {};
  },
  mounted() {
    this.loadProject();
  },
  methods: {
    loadProject: function() {
      document.body.scrollTo(0, 0);
      if (this.project) document.title = this.project.title + " - Ivo Ketelaar";
      else document.title = this.$route.params.id + " - Ivo Ketelaar";
    }
  },
  computed: {
    ...mapGetters(["projects"]),
    project: function() {
      const projects = this.projects.filter(
        project => project.id === this.$route.params.id
      );
      if (projects.length > 0) {
        return projects[0];
      } else {
        return {};
      }
    }
  },
  watch: {
    projects: function() {
      this.loadProject();
    },
    "$route.params.id": function() {
      this.loadProject();
    }
  }
};
</script>

<style scoped>
.project-title {
}

.project-description {
  font-size: 1.2em;
}

.title-year {
  font-size: 1.7rem;
  vertical-align: middle;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.youtube-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
